import FormLogo from "../../components/FormLogo";
import { useLocation, useNavigate } from "react-router-dom";

import "./register.css";
import { useEffect, useState } from "react";
import { useForm } from "../../context/FormContext";

function Register() {
  const {
    formData: {
      firstName,
      lastName,
      otherNames,
      email,
      youtubeVideoUrl,
      dateOfBirth,
      interests,
      languagesSpoken,
      languagesToTeach,
      workExperiences,
      educationalQualifications,
      aboutYou,
      aboutYouAsTeacher,
      certifications,
      selectedInterest,
      newLanguageSpoken,
      selectedLanguagesSpokenProficiency,
      selectedLanguageTaught,
      selectedLanguageTaughtProficiency,
      company,
      title,
      description,
      startDate,
      endDate,
      institution,
      newFieldOfStudy,
      selectedEduLevel,
      dateStartedEdu,
      dateEndedEdu,
      newCertificate,
      issuingOrganization,
      issueDate,
    },
    setFormData,
  } = useForm();

  const navigate = useNavigate();

  const interestOptions: string[] = [
    "Music",
    "Painting",
    "Swimming",
    "Exercise",
    "Movies",
    "Fashion and Style",
    "Research",
    "History",
    "Sports",
    "Acting",
    "Video-editing",
    "Instrumentation",
    "Home-management",
    "Event-management",
    "Novels",
    "Technology",
    "Health",
    "Business",
    "Books",
    "Design",
    "Food",
    "Visiting and Travel",
    "Writing",
    "Cooking",
  ];

  const handleFormDataInput = (e: any) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEndDate = (e: any) => {
    const today = new Date();

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    const todayDate = `${year}-${month}-${day}`;

    if (e.target.value > todayDate) {
      setFormData((prev) => ({
        ...prev,
        endDate: "",
      }));
      setEndDateValidattion("Enter the correct date");
      return;
    }

    setFormData((prev) => ({
      ...prev,
      endDate: e.target.value,
    }));
    setEndDateValidattion("");
  };

  const [endDateValidation, setEndDateValidattion] = useState("");

  const [organizationRequired, setOrganizationRequired] = useState<string>("");
  const [roleRequired, setRoleRequired] = useState<string>("");
  const [roleDescriptionRequired, setRoleDescriptionRequired] =
    useState<string>("");
  const [dateStartedRequired, setDateStartedRequired] = useState<string>("");
  const [dateEndedRequired, setDateEndedRequired] = useState<string>("");
  // trying to set the confirm is still working here
  const [currentlyWorkingHere, setCurrentlyWorkingHere] =
    useState<boolean>(false);

  const [institutionRequired, setInstitutionRequired] = useState<string>("");
  const [newFieldOfStudyRequired, setNewFieldOfStudyRequired] =
    useState<string>("");
  const [selectedEduLevelRequired, setSelectedEduLevelRequired] =
    useState<string>("");
  const [dateStartedEduRequired, setDateStartedEduRequired] =
    useState<string>("");
  const [dateEndedEduRequired, setDateEndedEduRequired] = useState<string>("");

  const [newCertificateRequired, setNewCertificateRequired] =
    useState<string>("");
  const [issuingOrganizationRequired, setIssuingOrganizationRequired] =
    useState<string>("");
  const [issueDateRequired, setissueDateRequired] = useState<string>("");

  const handleClick = () => {
    if (
      !firstName ||
      !lastName ||
      !email ||
      !youtubeVideoUrl ||
      !dateOfBirth ||
      !aboutYou ||
      !aboutYouAsTeacher
    ) {
      return;
    }

    navigate("/confirm");
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      firstName: firstName,
      lastName: lastName,
      otherNames: otherNames,
      email: email,
      youtubeVideoUrl: youtubeVideoUrl,
      dateOfBirth: dateOfBirth,
      interests: interests,
      languagesSpoken: languagesSpoken,
      languagesToTeach: languagesToTeach,
      workExperiences: workExperiences,
      educationalQualifications: educationalQualifications,
      aboutYou: aboutYou,
      aboutYouAsTeacher: aboutYouAsTeacher,
      certifications: certifications,
      selectedInterest: selectedInterest,
      newLanguageSpoken: newLanguageSpoken,
      selectedLanguagesSpokenProficiency: selectedLanguagesSpokenProficiency,
      selectedLanguageTaught: selectedLanguageTaught,
      selectedLanguageTaughtProficiency: selectedLanguageTaughtProficiency,
      company: company,
      title: title,
      description: description,
      startDate: startDate,
      endDate: endDate,
      institution: institution,
      newFieldOfStudy: newFieldOfStudy,
      selectedEduLevel: selectedEduLevel,
      dateStartedEdu: dateStartedEdu,
      dateEndedEdu: dateEndedEdu,
      newCertificate: newCertificate,
      issuingOrganization: issuingOrganization,
      issueDate: issueDate,
    }));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="form-container">
      <FormLogo />
      <h1 className="register-text">Register as a Language Instructor</h1>

      <form>
        <label className="is-required">First Name:</label>
        <input
          type="text"
          name="firstName"
          value={firstName}
          onChange={handleFormDataInput}
          required
        />

        <label className="is-required">Last Name:</label>
        <input
          type="text"
          name="lastName"
          value={lastName}
          onChange={handleFormDataInput}
          required
        />

        <label>Other Names:</label>
        <input
          type="text"
          name="otherNames"
          value={otherNames}
          onChange={handleFormDataInput}
        />

        <label className="is-required">Email Address</label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={handleFormDataInput}
          required
        />

        <label className="is-required">YouTube Url</label>
        <input
          type="url"
          name="youtubeVideoUrl"
          value={youtubeVideoUrl}
          onChange={handleFormDataInput}
          required
        />

        <label className="is-required">Date of Birth</label>
        <input
          type="date"
          name="dateOfBirth"
          value={dateOfBirth}
          onChange={handleFormDataInput}
          required
        />

        <fieldset>
          <legend>Interests:</legend>

          <ul>
            {interests.map((interest, key) => (
              <li key={key}>
                {interest} -{" "}
                <button
                  type="button"
                  className="cancel"
                  onClick={() => {
                    setFormData((prev) => ({
                      ...prev,
                      interests: interests.filter((i) => i !== interest),
                    }));
                  }}
                >
                  x
                </button>
              </li>
            ))}
          </ul>

          <select
            onChange={handleFormDataInput}
            value={selectedInterest}
            name="selectedInterest"
          >
            <option value="">- Select Interests -</option>
            {interestOptions.map((each, key) => (
              <option key={key} value={each}>
                {each}
              </option>
            ))}
          </select>

          <button
            type="button"
            onClick={() => {
              if (
                interests.includes(selectedInterest) ||
                selectedInterest === ""
              ) {
                setFormData((prev) => ({
                  ...prev,
                  selectedInterest: "",
                }));
                return;
              }

              setFormData((prev) => ({
                ...prev,
                interests: [...interests, selectedInterest],
              }));
              setFormData((prev) => ({
                ...prev,
                selectedInterest: "",
              }));
            }}
          >
            Add Interest
          </button>
        </fieldset>

        <fieldset>
          <legend>Spoken Languages:</legend>
          <ul>
            {languagesSpoken.map((language, key) => (
              <li key={key}>
                {language.language} - {language.proficiency + " "}
                <button
                  type="button"
                  className="cancel"
                  onClick={() => {
                    setFormData((prev) => ({
                      ...prev,
                      languagesSpoken: languagesSpoken.filter(
                        (i) => i.language !== language.language
                      ),
                    }));
                  }}
                >
                  x
                </button>
              </li>
            ))}
          </ul>
          <div id="languagesSpoken">
            <div>
              <input
                type="text"
                name="newLanguageSpoken"
                value={newLanguageSpoken}
                placeholder="language"
                onChange={handleFormDataInput}
              />
            </div>
            <div>
              <select
                name="selectedLanguagesSpokenProficiency"
                value={selectedLanguagesSpokenProficiency}
                onChange={handleFormDataInput}
              >
                <option value="">- Select Proficiency -</option>
                <option value="Native">Native</option>
                <option value="Expert">Expert</option>
                <option value="Fair">Fair</option>
                <option value="Novice">Novice</option>
              </select>
            </div>
          </div>
          <button
            type="button"
            onClick={() => {
              if (
                selectedLanguagesSpokenProficiency === "" ||
                newLanguageSpoken === ""
              ) {
                return;
              }

              if (
                languagesSpoken.find(
                  (a) =>
                    a.language.toLowerCase() === newLanguageSpoken.toLowerCase()
                )
              ) {
                setFormData((prev) => ({
                  ...prev,
                  newLanguageSpoken: "",
                  selectedLanguagesSpokenProficiency: "",
                }));
                return;
              }

              setFormData((prev) => ({
                ...prev,
                languagesSpoken: [
                  ...languagesSpoken,
                  {
                    language: newLanguageSpoken,
                    proficiency: selectedLanguagesSpokenProficiency,
                  },
                ],
              }));
              setFormData((prev) => ({
                ...prev,
                newLanguageSpoken: "",
                selectedLanguagesSpokenProficiency: "",
              }));
            }}
          >
            Add Spoken Languages
          </button>
        </fieldset>

        <fieldset>
          <legend>Language(s) to Teach:</legend>

          <ul>
            {languagesToTeach.map((language, key) => (
              <li key={key}>
                {language.language} - {language.proficiency + " "}
                <button
                  type="button"
                  className="cancel"
                  onClick={() => {
                    setFormData((prev) => ({
                      ...prev,
                      languagesToTeach: languagesToTeach.filter(
                        (i) => i.language !== language.language
                      ),
                    }));
                  }}
                >
                  x
                </button>
              </li>
            ))}
          </ul>

          <div id="languagesTaught">
            <select
              name="selectedLanguageTaught"
              value={selectedLanguageTaught}
              onChange={handleFormDataInput}
            >
              <option value="">- Select Language -</option>
              <option value="Igbo">Igbo</option>
              <option value="Yoruba">Yoruba</option>
              <option value="Swahili">Swahili</option>
            </select>

            <select
              name="selectedLanguageTaughtProficiency"
              value={selectedLanguageTaughtProficiency}
              onChange={handleFormDataInput}
            >
              <option value="">- Select Proficiency -</option>
              <option value="Native">Native</option>
              <option value="Expert">Expert</option>
              <option value="Fair">Fair</option>
              <option value="Novice">Novice</option>
            </select>
          </div>
          <button
            type="button"
            onClick={() => {
              if (
                selectedLanguageTaught === "" ||
                selectedLanguageTaughtProficiency === ""
              ) {
                return;
              }

              if (
                languagesToTeach.find(
                  (a) => a.language === selectedLanguageTaught
                )
              ) {
                setFormData((prev) => ({
                  ...prev,
                  selectedLanguageTaught: "",
                  selectedLanguageTaughtProficiency: "",
                }));
                return;
              }

              setFormData((prev) => ({
                ...prev,
                languagesToTeach: [
                  ...languagesToTeach,
                  {
                    language: selectedLanguageTaught,
                    proficiency: selectedLanguageTaughtProficiency,
                  },
                ],
              }));
              setFormData((prev) => ({
                ...prev,
                selectedLanguageTaught: "",
                selectedLanguageTaughtProficiency: "",
              }));
            }}
          >
            Add Language(s) to Teach
          </button>
        </fieldset>

        <fieldset>
          <legend>Work Experience:</legend>

          <ul>
            {workExperiences.map((experience, key) => (
              <li key={key}>
                <div className="experience">
                  <div>
                    <div className="exp-role">{experience.title}</div>
                    <div className="org">{experience.company}</div>
                    <div className="experience-date">
                      {" "}
                      <span>{experience.startDate} - </span>
                      <span> {experience.endDate}</span>{" "}
                    </div>
                    <div className="role-desc">{experience.description}</div>
                  </div>

                  <div>
                    <button
                      type="button"
                      className="cancel space-cancel"
                      onClick={() => {
                        setFormData((prev) => ({
                          ...prev,
                          workExperiences: workExperiences.filter(
                            (i) => i !== experience
                          ),
                        }));
                      }}
                    >
                      x
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div>
            <label htmlFor="organization">Organization</label>
            <input
              type="text"
              name="company"
              value={company}
              onChange={handleFormDataInput}
            />
          </div>
          <div className="required-field">
            {organizationRequired && <div>{organizationRequired}</div>}
          </div>

          <div>
            <label htmlFor="role">Role</label>
            <input
              type="text"
              name="title"
              value={title}
              onChange={handleFormDataInput}
            />
          </div>
          <div className="required-field">
            {roleRequired && <div>{roleRequired} </div>}
          </div>
          <div>
            <label htmlFor="roleDescription">Role Description</label>
            <textarea
              className="role-desc-input"
              name="description"
              value={description}
              onChange={handleFormDataInput}
            ></textarea>
          </div>
          <div className="required-field">
            {roleDescriptionRequired && <div>{roleDescriptionRequired} </div>}
          </div>
          <div>
            <label htmlFor="dateStarted">Date Started</label>
            <input
              type="date"
              name="startDate"
              value={startDate}
              onChange={handleFormDataInput}
            />
          </div>
          <div className="required-field">
            {dateStartedRequired && <div>{dateStartedRequired} </div>}
          </div>
          <div></div>

          <div>
            <label htmlFor="dateEnded">Date Ended</label>
            <input
              type="date"
              name="endDate"
              value={endDate}
              onChange={handleEndDate}
              disabled={currentlyWorkingHere}
            />
          </div>

          <div className="required-field">
            {dateEndedRequired && <div>{dateEndedRequired} </div>}
            {<div>{endDateValidation}</div>}
          </div>

          {/* ----- */}

          <div className="working-here-wrapper">
            <label className="working-here">Currently working here</label>
            <input
              type="checkbox"
              checked={currentlyWorkingHere}
              onChange={() => {
                const isCurrentlyWorkingHere = !currentlyWorkingHere;

                setCurrentlyWorkingHere(isCurrentlyWorkingHere);

                setDateEndedRequired("Fill in the end date");
                if (isCurrentlyWorkingHere) {
                  setDateEndedRequired("");
                  setEndDateValidattion("");
                }

                setFormData((prev) => ({
                  ...prev,
                  endDate: isCurrentlyWorkingHere ? "present" : "",
                }));
              }}
            />
          </div>

          <button
            type="button"
            onClick={() => {
              setCurrentlyWorkingHere(false);

              setOrganizationRequired("");
              if (company === "") {
                setOrganizationRequired("Fill in the organization");
              }

              setRoleRequired("");
              if (title === "") {
                setRoleRequired("Fill in the role");
              }

              setRoleDescriptionRequired("");
              if (description === "") {
                setRoleDescriptionRequired("Fill in the role description");
              }

              setDateStartedRequired("");
              if (startDate === "") {
                setDateStartedRequired("Fill in the start date");
              }

              setDateEndedRequired("");
              if (endDate === "") {
                setDateEndedRequired("Fill in the end date");
              }

              if (
                startDate === "" ||
                endDate === "" ||
                company === "" ||
                title === "" ||
                description === ""
              ) {
                return;
              }

              setFormData((prev) => ({
                ...prev,
                workExperiences: [
                  ...workExperiences,
                  {
                    startDate: startDate,
                    endDate: endDate,
                    company: company,
                    title: title,
                    description: description,
                  },
                ],
              }));
              setFormData((prev) => ({
                ...prev,
                company: "",
                title: "",
                description: "",
                startDate: "",
                endDate: "",
              }));
              setOrganizationRequired("");
              setRoleRequired("");
              setRoleDescriptionRequired("");
              setDateStartedRequired("");
              setDateEndedRequired("");
            }}
          >
            Add Work Experience
          </button>
        </fieldset>

        <fieldset>
          <legend>Education:</legend>

          <ul>
            {educationalQualifications.map((edu, key) => (
              <li key={key}>
                <div className="experience">
                  <div>
                    <div className="edu-level">{edu.level} </div>
                    <div className="study">{edu.fieldOfStudy} </div>
                    <div className="study">{edu.institution} </div>
                    <div className="edu-date">
                      {" "}
                      <span>{edu.startDate} </span> <span>{edu.endDate} </span>
                    </div>
                  </div>

                  <div>
                    <button
                      type="button"
                      className="cancel space-cancel"
                      onClick={() => {
                        setFormData((prev) => ({
                          ...prev,
                          educationalQualifications:
                            educationalQualifications.filter((a) => a !== edu),
                        }));
                      }}
                    >
                      x
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div>
            <label htmlFor="institution">Institution</label>
            <input
              type="text"
              name="institution"
              value={institution}
              onChange={handleFormDataInput}
            />
          </div>
          <div className="required-field">
            {institutionRequired && <div>{institutionRequired} </div>}
          </div>

          <div>
            <label htmlFor="fieldOfStudy">Area Of Study</label>
            <input
              type="text"
              name="newFieldOfStudy"
              value={newFieldOfStudy}
              onChange={handleFormDataInput}
            />
          </div>
          <div className="required-field">
            {newFieldOfStudyRequired && <div>{newFieldOfStudyRequired} </div>}
          </div>

          <div>
            <select
              name="selectedEduLevel"
              value={selectedEduLevel}
              onChange={handleFormDataInput}
            >
              <option value="">- Education Level -</option>
              <option value="Bsc or its equivalent">
                Bsc or its equivalent
              </option>
              <option value="WAEC or its equivalent">
                WAEC or its equivalent
              </option>
              <option value="Masters or its equivalent">
                Masters or its equivalent
              </option>
            </select>
          </div>
          <div className="required-field">
            {selectedEduLevelRequired && <div>{selectedEduLevelRequired} </div>}
          </div>

          <div>
            <label htmlFor="dateStartedEdu">Date Started</label>
            <input
              type="date"
              name="dateStartedEdu"
              value={dateStartedEdu}
              onChange={handleFormDataInput}
            />
          </div>
          <div className="required-field">
            {dateStartedEduRequired && <div>{dateStartedEduRequired} </div>}
          </div>

          <div>
            <label htmlFor="dateEndedEdu">Date Ended</label>
            <input
              type="date"
              name="dateEndedEdu"
              value={dateEndedEdu}
              onChange={handleFormDataInput}
            />
          </div>
          <div className="required-field">
            {dateEndedEduRequired && <div>{dateEndedEduRequired} </div>}
          </div>

          <button
            type="button"
            onClick={() => {
              setInstitutionRequired("");
              if (institution === "") {
                setInstitutionRequired("Fill in the institution");
              }

              setNewFieldOfStudyRequired("");
              if (newFieldOfStudy === "") {
                setNewFieldOfStudyRequired("Fill in the field of study");
              }

              setSelectedEduLevelRequired("");
              if (selectedEduLevel === "") {
                setSelectedEduLevelRequired("Fill in the level of education");
              }

              setDateStartedEduRequired("");
              if (dateStartedEdu === "") {
                setDateStartedEduRequired("Fill in the start date");
              }

              setDateEndedEduRequired("");
              if (dateEndedEdu === "") {
                setDateEndedEduRequired("Fill in the end date");
              }

              if (
                institution === "" ||
                newFieldOfStudy === "" ||
                selectedEduLevel === "" ||
                dateStartedEdu === "" ||
                dateEndedEdu === ""
              ) {
                return;
              }

              setFormData((prev) => ({
                ...prev,
                educationalQualifications: [
                  ...educationalQualifications,
                  {
                    institution: institution,
                    fieldOfStudy: newFieldOfStudy,
                    level: selectedEduLevel,
                    startDate: dateStartedEdu,
                    endDate: dateEndedEdu,
                  },
                ],
              }));
              setFormData((prev) => ({
                ...prev,
                institution: "",
                newFieldOfStudy: "",
                selectedEduLevel: "",
                dateStartedEdu: "",
                dateEndedEdu: "",
              }));
              setInstitutionRequired("");
              setNewFieldOfStudyRequired("");
              setSelectedEduLevelRequired("");
              setDateStartedEduRequired("");
              setDateEndedEduRequired("");
            }}
          >
            Add Education
          </button>
        </fieldset>

        <label className="is-required">About You:</label>
        <textarea
          className="about-me-input"
          name="aboutYou"
          value={aboutYou}
          onChange={handleFormDataInput}
          required
        ></textarea>

        <div className="word-limit">
          Not less than 50 and not more than 150 words
        </div>

        <label className="is-required">About You As A Teacher:</label>
        <textarea
          className="me-as-teacher-input is-required"
          name="aboutYouAsTeacher"
          value={aboutYouAsTeacher}
          onChange={handleFormDataInput}
          required
        ></textarea>

        <div className="word-limit">
          Not less than 50 and not more than 150 words
        </div>

        <fieldset>
          <legend>Certifications:</legend>

          <ul>
            {certifications.map((edu, key) => (
              <li key={key}>
                <div className="experience">
                  <div>
                    <div className="edu-level">{edu.name} </div>
                    <div className="study">{edu.issuingOrganization} </div>
                    <div className="edu-date">
                      {" "}
                      <span>{edu.issueDate} </span>{" "}
                    </div>
                  </div>

                  <div>
                    <button
                      type="button"
                      className="cancel space-cancel"
                      onClick={() => {
                        setFormData((prev) => ({
                          ...prev,
                          certifications: certifications.filter(
                            (a) => a !== edu
                          ),
                        }));
                      }}
                    >
                      x
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div>
            <label htmlFor="newCertificate">Name of Certification</label>
            <input
              type="text"
              name="newCertificate"
              value={newCertificate}
              onChange={handleFormDataInput}
            />
          </div>
          <div className="required-field">
            {newCertificateRequired && <div>{newCertificateRequired} </div>}
          </div>

          <div>
            <label htmlFor="issuingOrganization">Issuing Organization</label>
            <input
              type="text"
              name="issuingOrganization"
              value={issuingOrganization}
              onChange={handleFormDataInput}
            />
          </div>
          <div className="required-field">
            {issuingOrganizationRequired && (
              <div>{issuingOrganizationRequired} </div>
            )}
          </div>

          <div>
            <label htmlFor="issueDate">Issue Date</label>
            <input
              type="date"
              name="issueDate"
              value={issueDate}
              onChange={handleFormDataInput}
            />
          </div>
          <div className="required-field">
            {issueDateRequired && <div>{issueDateRequired} </div>}
          </div>

          <button
            type="button"
            onClick={() => {
              setNewCertificateRequired("");
              if (newCertificate === "") {
                setNewCertificateRequired(
                  "Fill in the name of the certificate"
                );
              }

              setIssuingOrganizationRequired("");
              if (issuingOrganization === "") {
                setIssuingOrganizationRequired(
                  "Fill in the field for issuing organization"
                );
              }

              setissueDateRequired("");
              if (issueDate === "") {
                setissueDateRequired("Fill in the field for issue date");
              }

              if (
                newCertificate === "" ||
                issuingOrganization === "" ||
                issueDate === ""
              ) {
                return;
              }

              setFormData((prev) => ({
                ...prev,
                certifications: [
                  ...certifications,
                  {
                    name: newCertificate,
                    issuingOrganization: issuingOrganization,
                    issueDate: issueDate,
                  },
                ],
              }));
              setFormData((prev) => ({
                ...prev,
                newCertificate: "",
                issuingOrganization: "",
                issueDate: "",
              }));
              setInstitutionRequired("");
              setNewFieldOfStudyRequired("");
              setSelectedEduLevelRequired("");
              setDateStartedEduRequired("");
              setDateEndedEduRequired("");
            }}
          >
            Add Certification
          </button>
        </fieldset>

        <button className="submit-style" type="submit" onClick={handleClick}>
          Next
        </button>
      </form>
    </div>
  );
}

export default Register;
